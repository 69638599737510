import { useEffect, useState } from 'react';
import { userSettingsDb } from '@Dexie/userSettingsDB';
import { useLiveQuery } from 'dexie-react-hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import { OrderStockType } from '@Constants/account';

const useUserSettings = () => {
	const [isVehiclePartsListView, setIsVehiclePartsListview] = useState(null);
	const [useShowNetPriceShortcut, setUseShowNetPriceShortcut] = useState(null);
	const [selectedPartLanguage, setSelectedPartLanguage] = useState(null);
	const [contentLanguages, setContentLanguages] = useState(null);
	const [selectedWebPartsOrderType, setSelectedWebPartsOrderType] = useState(null);
	const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);
	const [userSettings, userSettingsLoaded] = useLiveQuery(
		() => userSettingsDb.userSettingsData.toArray().then(data => [data[data?.length - 1], true]),
		[],
		[]
	);
	const allOrderTypes = Object.values(OrderStockType);
	const unavailablePartOrderTypes = useSelector<RootState, string[]>(
		state => state.configuration.unavailablePartOrderTypes
	);

	useEffect(() => {
		if (unavailablePartOrderTypes.includes(selectedWebPartsOrderType)) {
			const availableOrderType = allOrderTypes.find(type => !unavailablePartOrderTypes.includes(type));
			if (availableOrderType) {
				setSelectedWebPartsOrderType(availableOrderType);
			}
		}
	}, [selectedWebPartsOrderType]);

	useEffect(() => {
		if (userSettings && userSettingsLoaded) {
			setIsVehiclePartsListview(userSettings.isVehiclePartsListView);
			setUseShowNetPriceShortcut(userSettings.useShowNetPriceShortcut);
			setSelectedPartLanguage(userSettings.selectedPartLanguage);
			setContentLanguages(userSettings.contentLanguages);
			setSelectedPartLanguage(userSettings.selectedPartLanguage);
			setSelectedWebPartsOrderType(userSettings.selectedWebPartsOrderType);
			setSelectedDeliveryType(userSettings.selectedDeliveryType);
		}
	}, [userSettingsLoaded, userSettings]);

	return {
		isVehiclePartsListView,
		useShowNetPriceShortcut,
		selectedPartLanguage,
		contentLanguages,
		selectedWebPartsOrderType,
		selectedDeliveryType,
	};
};

export default useUserSettings;
