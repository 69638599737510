import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@Lib/i18n';
import { RootState } from '@Redux';
import { downloadFile } from '@Helpers/common/media';
import { getPartsOrderXFRFile, getPartsOrderXLSXFile } from '@Apis/orders';
import ExportMenu, { ExportMenuOption } from '@Shared/menus/ExportMenu';
import { FileExtensions, MIMETypes } from '@Constants/common';

const OrderExport = ({ orderId }) => {
	const { t } = useTranslation(['common', 'orders']);
	const [loading, setLoading] = useState<boolean>(false);
	const userId = useSelector<RootState, string>(state => state.user.userId);

	const handleXLSXExport = () => {
		setLoading(true);
		getPartsOrderXLSXFile(orderId)
			.then(file => downloadFile(file, `${userId}${FileExtensions.XLSX}`.toUpperCase(), MIMETypes.XLSX))
			.finally(() => setLoading(false));
	};

	const handleXFRExport = () => {
		setLoading(true);
		getPartsOrderXFRFile(orderId)
			.then(file => downloadFile(file, `${userId}${FileExtensions.XFR}`.toUpperCase()))
			.finally(() => setLoading(false));
	};

	return (
		<ExportMenu>
			<ExportMenuOption optionText={t('orders:export-xfr-format')} onClick={handleXFRExport} loading={loading} />
			<ExportMenuOption optionText={t('orders:export-xlsx-format')} onClick={handleXLSXExport} loading={loading} />
		</ExportMenu>
	);
};

export default OrderExport;
