import get from 'lodash/get';
import find from 'lodash/find';
import { getRemanPartOrEngineInfo } from '@Apis/shop';
import {
	SET_BREADCRUMB_ENGINE_ID,
	SET_REMAN_SCREEN_TYPE,
	SET_SEARCHED_PRODUCT_WITH_PART_NUMBER,
	SET_VEHICLE_TYPE,
	SET_SEARCHED_ENGINES,
} from '@Reducers/remanSearch/actions';
import { TOGGLE_REMAN_PARTS_MODAL } from '@Reducers/modals/actions';
import client, { getAllNodeIdsByDocumentType } from '@Umbraco/client';
import routes, { getRoutesAs } from '@Routes';
import { IDENTIFIER_DOCUMENT_TYPE_MAPPING } from '@Umbraco/update-content-id/identifier-document-type';
import { getRemanPartsPage, getRemanVehiclePage, RemanCategoryType } from '@Umbraco/product-store';
import { StorageKeys } from '@Helpers/storage';
import { MAP_TO_REMAN_VEHICLES } from '@Constants/product';
import { ModalScreenTypes } from '@Constants/parts';
import { ResponseCode } from '@Constants/common';

export const getRemanCategoryContent = (data, categorySlug) => {
	const { categories } = data;
	const content = {
		categoryContent: find(categories, category => category.slug === categorySlug),
	};
	return content;
};

export const getCategoryPageInfoContent = (data, categorySlug) => {
	const { categories, pageInfo } = data;
	const index = categories.findIndex(category => category.slug === categorySlug);
	return pageInfo[index];
};

export const getRemanProductContent = (data, productSlug) => {
	const { products } = data;
	const content = find(products, product => product.slug === productSlug);

	return content;
};

export const matchWithPartNumberSearch = (cmsData, partIdentifier, availability, partNumber) => {
	const { categories } = cmsData;
	const matchedProducts = [];
	categories.forEach(category => {
		const currentProduct = get(category, 'products', []).find(
			product => get(product, 'content.identifier', '') === partIdentifier
		);
		if (currentProduct !== undefined) {
			currentProduct.partNumber = partNumber;
			currentProduct.availabilityInfo = availability;
			matchedProducts.push(currentProduct);
		}
	});
	return matchedProducts;
};

export const createMappedCategoriesArray = (cmsData, productIdentifiers, dispatch = null) => {
	const { categories } = cmsData;

	categories.forEach(category => {
		if (category.categoryIdentifier !== RemanCategoryType.ENGINES) {
			category.products = get(category, 'products', []).filter(
				product => productIdentifiers.includes(get(product, 'content.identifier', '')) && product
			);
		} else {
			dispatch(SET_BREADCRUMB_ENGINE_ID(get(category, 'id', '')));
		}
	});

	return categories;
};

export const remanPartNumberSearch = async (
	store,
	router,
	language,
	searchTerm,
	searchTermType,
	countryId,
	countryIsoCode,
	setApiError,
	setLoading,
	setDealerNotFound
) => {
	const { market } = router.query;

	const response = await getRemanPartOrEngineInfo(language, searchTerm, countryId, countryIsoCode).catch(err => {
		if (err.response.data.ResponseCode === ResponseCode.NOT_FOUND) {
			setDealerNotFound(true);
		} else {
			setApiError(true);
		}
	});

	setLoading(true);

	if (response && response.remanPartEngineInfo) {
		const categories = RemanCategoryType.ENGINES.toLowerCase();
		if (response.remanPartEngineInfo.remanEngines.length === 0) {
			setApiError(true);
			setLoading(false);
			return;
		}
		const { documentType } = IDENTIFIER_DOCUMENT_TYPE_MAPPING.REMAN_VEHICLE_PAGE;
		const documentIds = await getAllNodeIdsByDocumentType(documentType);
		const id = get(
			find(
				documentIds,
				item =>
					item.Identifier === MAP_TO_REMAN_VEHICLES[response.remanPartEngineInfo.remanEngines[0].engineInfo.vehicleType]
			),
			'Key',
			null
		);
		if (id === null) {
			store.dispatch(SET_REMAN_SCREEN_TYPE(ModalScreenTypes.ERROR));
			store.dispatch(TOGGLE_REMAN_PARTS_MODAL());
			return;
		}
		const content = await getRemanVehiclePage(id, router.query.market, language).catch(() => {
			store.dispatch(SET_REMAN_SCREEN_TYPE(ModalScreenTypes.ERROR));
			store.dispatch(TOGGLE_REMAN_PARTS_MODAL());
		});
		store.dispatch(SET_SEARCHED_PRODUCT_WITH_PART_NUMBER(createMappedCategoriesArray(content, [], store.dispatch)));
		const vehicle = MAP_TO_REMAN_VEHICLES[
			response.remanPartEngineInfo.remanEngines[0].engineInfo.vehicleType
		].toLowerCase();
		store.dispatch(
			SET_VEHICLE_TYPE(MAP_TO_REMAN_VEHICLES[response.remanPartEngineInfo.remanEngines[0].engineInfo.vehicleType])
		);

		store.dispatch(SET_SEARCHED_ENGINES(response.remanPartEngineInfo));
		router.push(
			routes.REMAN_PARTS_ENGINE_SUGGESTIONS,
			getRoutesAs(routes.REMAN_PARTS_ENGINE_SUGGESTIONS, { market, vehicle, categories })
		);
		setLoading(false);
		return;
	}

	if (response) {
		store.dispatch(TOGGLE_REMAN_PARTS_MODAL(searchTerm, response, searchTermType));
		store.dispatch(SET_REMAN_SCREEN_TYPE(ModalScreenTypes.PRODUCT_CATEGORY));
	}
	setLoading(false);
};

export const cacheRemanRequestModal = (content, router) => {
	const data = { content, path: router.pathname, asPath: router.asPath };
	sessionStorage.setItem(StorageKeys.STORAGE_KEY_REMAN_REQUEST_MODAL, JSON.stringify(data));
};

export const clearCacheRemanRequestModal = () => {
	sessionStorage.removeItem(StorageKeys.STORAGE_KEY_REMAN_REQUEST_MODAL);
};

export const getEngineSuggestionBreadCrumbTitles = (vehicleType, engineId, market, lang) => {
	const vehicleTitle = getRemanPartsPage(market as string, lang)
		.then(res => {
			return res.vehicleTypes.find(type => type.identifier === vehicleType).title;
		})
		.catch(() => {
			return null;
		});
	const engineTitle = client(`/content/${engineId}`, market, lang)
		.then(res => {
			return res.title;
		})
		.catch(() => {
			return null;
		});
	return Promise.all([vehicleTitle, engineTitle]);
};
