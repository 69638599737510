import { RootState } from '@Redux';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import Modal, { ModalSize } from '@isp/modal';
import { useDispatch, useSelector } from 'react-redux';
import ISPIcon, { IconType, IconVariant, IconSize } from '@isp/icon';
import Button, { ButtonVariant } from '@isp/button';
import { useTranslation } from 'react-i18next';
import { logout } from '@User-operations';
import { TOGGLE_LOGIN_ERROR_MODAL } from '@Reducers/modals/actions';
import { LoginErrors } from '@Partials/NewHome';
import { Buttons, Components, getComponentId, Pages } from '@Constants/google-analytics';

export interface LoginErrorProps {
	errorMessage: string;
	description: string;
	loginError: string | string[];
}

const PublicLoginError = () => {
	const { t } = useTranslation(['common', 'login']);
	const { errorMessage, description, loginError } = useSelector<RootState, LoginErrorProps>(
		state => state.modals.loginErrorProps
	);
	const dispatch = useDispatch();
	const onClose = () => dispatch(TOGGLE_LOGIN_ERROR_MODAL());

	const onLogoutClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		logout();
	};

	const handleClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		onClose();
	};

	return (
		<>
			<Modal show header size={ModalSize.SM} onClose={handleClose} closeOnClickOutside>
				<>
					<div className="train train__center  train__flex">
						<ISPIcon type={IconType.LOCK_CLOSED2} variant={IconVariant.AZURE} size={IconSize.VERY_HUGE} />
						<section>
							<p className="fs-38">{errorMessage}</p>
							<p className="fs-16 ">{description}</p>
						</section>
					</div>
					<div className="p-1 text-right">
						<Button
							className="p-h-5"
							onClick={e => (loginError === LoginErrors.AUTHORIZATION ? onLogoutClick(e) : handleClose(e))}
							variant={ButtonVariant.PRIMARY}
							outline
							id={
								loginError === LoginErrors.AUTHORIZATION
									? getComponentId([Pages.HOME, Components.LOGIN, Components.ERROR, Buttons.LOGOUT])
									: getComponentId([Pages.HOME, Components.LOGIN, Components.ERROR, Buttons.CLOSE])
							}
						>
							{loginError === LoginErrors.AUTHORIZATION ? t('common:logout') : t('common:close')}
						</Button>
					</div>
				</>
			</Modal>
		</>
	);
};

export default PublicLoginError;
