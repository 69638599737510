import { actionTypes } from '@Reducers/faq/actionTypes';
import { getFAQCategory } from '@Umbraco/faq';
import { RouteAvailableSoftwareProducts } from '@Constants/faq';
import { ProductRoute } from './models';

export const GET_PRODUCT_ROUTES = (id, market, language, slug) => dispatch => {
	dispatch({
		type: actionTypes.GET_PRODUCT_ROUTES,
		productRoutes: [],
	});
	return getFAQCategory(id, market, language, slug).then(res => {
		const updatedRoutes = res.childCategories
			.filter(childCategory => childCategory.subcategoryIdentifier in RouteAvailableSoftwareProducts)
			.map(childCategory => {
				const productRoute: ProductRoute = {
					productKey: childCategory.subcategoryIdentifier,
					slug: childCategory.slug,
				};
				return productRoute;
			});

		dispatch({ type: actionTypes.GET_PRODUCT_ROUTES, productRoutes: updatedRoutes });
	});
};
