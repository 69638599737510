import { VFC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { TOGGLE_LOGIN_ERROR_MODAL } from '@Reducers/modals/actions';
import cN from 'classnames';
import NextI18Next, { Trans, useTranslation } from '@Lib/i18n';
import { login } from '@User-operations';
import { getFocalPointClass } from '@Umbraco/media';
import { HomePageData, toBenefitModuleData, toPlatformPreviewModuleData, toQuoteModuleData } from '@Umbraco/home';
import getSalesOffers from '@Umbraco/sales-offers';
import { PushDataToTagManagerForButtons, PushEventToDataLayer } from '@Helpers/google-analytics/pushDataToTagManager';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { useResize } from '@Helpers/hooks/useEvent';
import { useWindowIntersect } from '@isp/sticky/useWindowIntersect';
import routes, { getRoutesAs } from '@Routes';
import B2BConnectLogo from '@Static/images/b2b-connect-logo.png';
import Button, { ButtonVariant } from '@isp/button';
import { IconType } from '@isp/icon';
import { LinkVariant } from '@isp/link';
import GlanceModule from '@Partials/NewHome/Modules/GlanceModule';
import BenefitsModule from '@Partials/NewHome/Modules/BenefitsModule';
import PlatformPreviewModule from '@Partials/NewHome/Modules/PlatformPreviewModule';
import RegisterModule from '@Partials/NewHome/Modules/RegisterModule';
import QuoteModule from '@Partials/NewHome/Modules/QuoteModule';
import CMSContent, { CMSContentThemes } from '@Shared/cms-content/CMSContent';
import CampaignBanner from '@Shared/CampaignBanner';
import InternalLink from '@Shared/links/InternalLink';
import { Buttons, Components, EventActions, EventCategories, Pages, getComponentId } from '@Constants/google-analytics';
import {
	HOME_LOGO_HEIGHT,
	HOME_LOGO_WIDTH,
	HomeModules,
	MARGIN_TOP_SCROLL_BUTTON,
	STICKY_TOP_SCOLL_BUTTON,
} from '@Constants/home';
import { Events } from '@Constants/common';
import { INITIAL_FAQ_CATEGORY_SLUG } from '@Constants/faq';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import './index.scss';
import RegisterButton from './Shared/RegisterButton';

export enum LoginErrors {
	SSO = 'SSOError',
	AUTHORIZATION = 'unauthorized',
	INTERNAL = 'internalError',
}

const Home: VFC<HomePageData> = ({ sections = [], headerBanner }) => {
	const { t } = useTranslation(['common', 'login']);
	const dispatch = useDispatch();
	const [offers, setOffers] = useState([]);
	const registrationRef = useRef(null);
	const registrationRefModule = useRef(null);
	const language = geti18nLanguage(NextI18Next.i18n.language);
	const { market } = useRouter().query;
	const { loginError } = useRouter().query;

	const getSection = data => {
		const sectionMap = {
			[HomeModules.AT_A_GLANCE_MODULE]: <GlanceModule data={data} />,
			[HomeModules.BENEFITS_MODULE]: <BenefitsModule {...toBenefitModuleData(data)} />,
			[HomeModules.PLATFORM_PREVIEW_MODULE]: <PlatformPreviewModule {...toPlatformPreviewModuleData(data)} />,
			[HomeModules.QUOTE_MODULE]: <QuoteModule {...toQuoteModuleData(data)} />,
			[HomeModules.REGISTER_MODULE]: <RegisterModule data={data} registrationRef={registrationRefModule} />,
		};
		const { identifier } = data;

		return sectionMap[identifier];
	};

	const onClickHelpSection = () => {
		dispatch(TOGGLE_LOGIN_ERROR_MODAL());
	};

	const getLoginErrorMessage = () => {
		const helpRouteAs = getRoutesAs(routes.FAQ_CATEGORY, { market, '...categories': INITIAL_FAQ_CATEGORY_SLUG });
		let errorMessage;
		let description;

		if (loginError === LoginErrors.SSO) {
			errorMessage = t('login:login-unavailable');
			description = t('login:sso-error-message');
		}
		if (loginError === LoginErrors.AUTHORIZATION) {
			errorMessage = t('login:no-access');
			description = (
				<Trans i18nKey={'login:authorization-error-message'}>
					{
						// eslint-disable-next-line max-len
						'Please contact your retailer or the B2B Connect support team. Alternatively, you can visit our '
					}
					<InternalLink
						href={routes.FAQ_CATEGORY}
						as={helpRouteAs}
						variant={LinkVariant.SECONDARY}
						underline
						inline
						dataQA={DATA_QA.PUBLIC_LOGIN_ERROR_MODAL_HELP_SECTION_LINK}
						id={getComponentId([Pages.GLOBAL, Components.NEED_HELP, Buttons.LINK])}
						onClick={() => onClickHelpSection()}
					>
						{t('common:help-section')}
					</InternalLink>
				</Trans>
			);
		}
		if (loginError === LoginErrors.INTERNAL) {
			errorMessage = t('common:unknown-error');
			description = (
				<Trans i18nKey={'login:internal-error-message'}>
					{
						// eslint-disable-next-line max-len
						'That was not our intention. Please try again later. If the problem persists, contact us via the '
					}
					<InternalLink
						href={routes.FAQ_CATEGORY}
						as={helpRouteAs}
						variant={LinkVariant.SECONDARY}
						underline
						inline
						dataQA={DATA_QA.PUBLIC_LOGIN_ERROR_MODAL_HELP_SECTION_LINK}
						id={getComponentId([Pages.GLOBAL, Components.NEED_HELP, Buttons.LINK])}
						onClick={() => onClickHelpSection()}
					>
						{t('common:help-section')}
					</InternalLink>
				</Trans>
			);
		}
		return { errorMessage, description };
	};

	const { errorMessage, description } = getLoginErrorMessage();

	const fetchOffers = () =>
		getSalesOffers(market as string, language).then(res => {
			setOffers(res);
		});

	useEffect(() => {
		fetchOffers();
		if (loginError) {
			dispatch(TOGGLE_LOGIN_ERROR_MODAL({ errorMessage, description, loginError }));
		}
	}, [language]);

	return (
		<>
			<div className="public-home disp-grid">
				<HeaderBanner headerBanner={headerBanner} registrationRef={registrationRef} />
				{sections?.map(section => (
					<div key={section._id}>{getSection(section)}</div>
				))}
				<StickyRegisterButton registrationRef={registrationRef} registrationRefModule={registrationRefModule} />
			</div>
			{offers && <CampaignBanner campaignItems={offers} autoplay />}
		</>
	);
};

const StickyRegisterButton = ({ registrationRef, registrationRefModule }) => {
	const isRegisterInViewport = useWindowIntersect(registrationRef);
	const isRegisterModuleInViewport = useWindowIntersect(registrationRefModule);
	const showStickyButton = registrationRef?.current && registrationRefModule?.current;

	return (
		showStickyButton && (
			<div className="public-home__register" data-visible={!isRegisterInViewport && !isRegisterModuleInViewport}>
				<RegisterButton className="p-h-10" id={getComponentId([Pages.HOME, Components.STICKY, Buttons.REGISTER])} />
			</div>
		)
	);
};

const HeaderBanner = ({ headerBanner, registrationRef }) => (
	<div
		className={cN(
			'public-home__banner',
			`${getFocalPointClass(headerBanner?.image?.focalPointTop, headerBanner?.image?.focalPointLeft, 'background')}`
		)}
		style={{ backgroundImage: `url("${headerBanner?.image?.url}")` }}
	>
		<img
			className="w-100-p h-100-p"
			src={headerBanner?.image?.url}
			style={{ visibility: 'hidden' }}
			alt={headerBanner?.image?.altText || 'B2B Connect'}
		/>
		<div className="public-home__banner__content color-white p-v-10 p-r-10 p-l-10">
			<div className="train__justify-center disp-flex-column">
				<img
					alt="B2B Connect"
					src={B2BConnectLogo}
					width={HOME_LOGO_WIDTH}
					height={HOME_LOGO_HEIGHT}
					className="m-b-12"
				/>
				<h1 className="DaimlerCAC-Regular fs-80 lh-normal fw-normal">{headerBanner.title}</h1>
				<CMSContent theme={CMSContentThemes.LIGHT} content={headerBanner.description} contentClassName="p-0 fs-18" />
				<BannerRegister registrationRef={registrationRef} />
			</div>
			<ScrollButton />
		</div>
	</div>
);

const BannerRegister = ({ registrationRef }) => {
	const { t } = useTranslation(['common']);

	const onLoginClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.LINK,
			action: EventActions.LOGIN,
		});
		login();
	};

	return (
		<div className="public-home__banner__register m-t-5">
			<Button
				outline
				onClick={onLoginClick}
				className="p-h-4 fs-16"
				variant={ButtonVariant.TERTIARY}
				id={getComponentId([Pages.HOME, Components.HEADER, Components.LOGIN, Buttons.LOGIN])}
			>
				{t('common:login')}
			</Button>
			<RegisterButton
				registrationRef={registrationRef}
				id={getComponentId([Pages.HOME, Components.BANNER, Buttons.REGISTER])}
			/>
		</div>
	);
};

const ScrollButton = () => {
	const { t } = useTranslation(['common']);
	const [distanceToScrollButton, setDistanceToScrollButton] = useState(0);

	const handleTopDistance = () => {
		const topElement = document.querySelector(`.public-home__banner__register`);
		setDistanceToScrollButton(window.scrollY + topElement?.getBoundingClientRect().bottom + STICKY_TOP_SCOLL_BUTTON);
	};

	useResize(handleTopDistance);

	useEffect(() => {
		handleTopDistance();
	}, []);

	const onClick = () => {
		const nextModule = document.querySelector(`.home__modules`);
		const yCoordinate = window.scrollY + nextModule?.getBoundingClientRect().top - MARGIN_TOP_SCROLL_BUTTON;
		window.scroll({ top: yCoordinate, behavior: 'smooth' });
	};

	return (
		distanceToScrollButton !== 0 && (
			<div className="public-home__scroll" style={{ top: `${distanceToScrollButton}px` }}>
				<Button onClick={onClick} variant={ButtonVariant.FLAT_LIGHT} startIconType={IconType.ARROW_DOWN}>
					{t('common:scroll')}
				</Button>
			</div>
		)
	);
};

export default Home;
