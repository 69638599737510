import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '@Redux';
import { TOGGLE_ADD_BASKET_DIRECT_MODAL, TOGGLE_ERROR_MODAL } from '@Reducers/modals/actions';
import { getPartPriceAndAvailability, getPartPriceAndAvailabilityByDealer, updateWebpartsBasket } from '@Apis/shop';
import { GET_WEBPARTS_BASKET_PRODUCTS, UPDATE_BASKET } from '@Reducers/shop/actions';
import { Retailer } from '@Reducers/organization/models';
import { BasketDealerInfoSummary, UpdateWebpartsBasketRequestBody } from '@Reducers/shop/models';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { getRetailersStates } from '@Helpers/retailer/GetRetailersStates';
import { extractCurrencySymbol } from '@Helpers/shop/price';
import { getDataState } from '@Helpers/common/getDataState';
import useUserSettings from '@Helpers/hooks/useUserSettings';
import Modal, { FooterBackground, ModalSize } from '@isp/modal';
import PartItem from '@Shared/webparts/NewCatalogue/PartDrawing/PartList/PartItem';
import Separator, { BorderVariant } from '@isp/separator';
import Banner from '@Partials/Vehicle/WebpartsCampaigns/Banner';
import Loading, { LoadingSizes } from '@isp/loading';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import { ResponseCode } from '@Constants/common';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';
import { OrderType } from '@Constants/orders';
import { STEPPER_MIN_COUNT } from '@Constants/product/equipment';
import './index.scss';

const AddToBasketDirectModal = (): JSX.Element => {
	const { t } = useTranslation(['shop', 'common']);
	const router = useRouter();
	const dispatch: AppDispatch = useDispatch();
	const { partNumber, GSSNID, quantity } = router.query;
	const initialStepperValue = quantity || STEPPER_MIN_COUNT;

	const [part, setPart] = useState(null);
	const [dealer, setDealer] = useState(null);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [stepperValue, setStepperValue] = useState(initialStepperValue);

	const userId = useSelector<RootState, string>(state => state.user.userId);
	const preferredRetailer = useSelector<RootState, Retailer>(
		state => getRetailersStates(state.organization.dealers).preferredRetailer
	);
	const showNetPrice = useSelector<RootState, boolean>(state => state.shop.showNetPrice);
	const dealers = useSelector<RootState, Retailer[]>(state => state.organization?.dealers);

	const { isDiscountGroupVisible } = preferredRetailer?.visibilities || {};
	const { listPricePerUnit, netPricePerUnit } = part?.shoppingDetails?.price || {};
	const currency = extractCurrencySymbol(listPricePerUnit || netPricePerUnit || '');

	const { loading: partLoading, error: partError } = getDataState(part);
	const { selectedWebPartsOrderType } = useUserSettings();

	const onClose = () => dispatch(TOGGLE_ADD_BASKET_DIRECT_MODAL());

	const findDealerName = (dealers, GSSNID) => {
		if (GSSNID) {
			const selectedDealer = dealers.find(dealer => dealer.gssnId === GSSNID);
			return selectedDealer ? selectedDealer.name : null;
		}
		return preferredRetailer.name;
	};

	const findCustomerNumber = (dealers, GSSNID) => {
		if (GSSNID) {
			const selectedDealer = dealers.find(dealer => dealer.gssnId === GSSNID);
			return selectedDealer ? selectedDealer.customerNumberList[0] : null;
		}
		return dealer?.deliveryAddressId;
	};

	const selectedDealerName = findDealerName(dealers, GSSNID);
	const customerNumber = findCustomerNumber(dealers, GSSNID);

	const handleStepperChange = value => {
		setStepperValue(value);
	};

	const bannerContent = {
		name: t('shop:add-part-directly-modal-title'),
		subtitle: (
			<Trans i18nKey="shop:add-part-directly-modal-content" values={{ selectedDealerName }}>
				{'Sold by: '}
				<span>{selectedDealerName}</span>
			</Trans>
		),
	};

	const getPriceTitle = () => {
		const discountTitle = isDiscountGroupVisible ? 'vehicles:net-price-list-price-title' : 'vehicles:net-price-title';
		return !showNetPrice ? 'vehicles:list-price-title' : discountTitle;
	};

	const handleClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		onClose();
	};

	const handleSave = async () => {
		setLoading(true);

		const basketRetailerRequestData: BasketDealerInfoSummary = {
			gssnId: dealer.gssnId || dealer.retailerId,
			userId: dealer.userId || userId,
			companyId: dealer.companyId,
			customerNumber,
		};

		const requestData: UpdateWebpartsBasketRequestBody = {
			finNumber: '',
			partNumber: partNumber as string,
			quantity: Number(stepperValue),
			basketDealer: basketRetailerRequestData,
			isRecommended: false,
		};

		try {
			await dispatch(
				UPDATE_BASKET({
					data: requestData,
					addedProductName: part.designation,
					showNotification: true,
					quantity: Number(stepperValue),
					useTimeZone: true,
					cartType: OrderType.WEBPARTS,
					updateBasketFunction: updateWebpartsBasket,
				})
			).then(() => dispatch(GET_WEBPARTS_BASKET_PRODUCTS(true)));

			setErrorMessage(null);
			onClose();
		} catch (error) {
			setErrorMessage(t('shop:add-part-directly-modal-error'));
		} finally {
			setLoading(false);
		}
	};

	const params = {
		partNumber,
		dealerId: GSSNID,
		customernumber: customerNumber,
		orderType: selectedWebPartsOrderType,
	};

	const fetchPartDataByDealer = params => {
		return getPartPriceAndAvailabilityByDealer(params)
			.then(res => handlePriceResponse(res))
			.catch(() => setPart(null));
	};

	const fetchPartData = (partNumber, orderType) => {
		return getPartPriceAndAvailability([partNumber], { orderType })
			.then(result => handlePriceResponse(result))
			.catch(() => setPart(null));
	};

	const handlePriceResponse = response => {
		if (response.responseCode === ResponseCode.SUCCESS) {
			const partData = response?.data[0] || response?.data?.partList[0];
			const dealerCustomerInfo = response?.data[1] || response?.data?.dealerInfo;
			setDealer(dealerCustomerInfo);
			if (partData) {
				const modifiedPartData = {
					...partData,
					partNumber: partData.position.partNumber,
					displayName: partData.designation,
					...(quantity && { quantity: Number(quantity) }),
				};
				setPart(modifiedPartData);
			}
		} else if (response.responseCode === ResponseCode.NOT_FOUND) {
			setPart(null);
			dispatch(TOGGLE_ERROR_MODAL());
		}
	};

	useEffect(() => {
		if (selectedWebPartsOrderType && GSSNID) {
			fetchPartDataByDealer(params);
		} else if (selectedWebPartsOrderType) {
			fetchPartData(partNumber, selectedWebPartsOrderType);
		}
	}, [selectedWebPartsOrderType, GSSNID]);

	return (
		<>
			{partLoading && <Loading size={LoadingSizes.TINY} className="text-center m-2" />}
			{!partError && !partLoading && (
				<Modal
					show
					footerEdit
					closeOnClickOutside
					isCustomModal
					size={ModalSize.MD}
					footerEditBgColor={FooterBackground.WHITE}
					primaryButtonText={t('common:cancel')}
					primaryButtonAction={e => handleClose(e)}
					primaryButtonId={getComponentId([Pages.MODAL, Components.ADD_TO_BASKET_DIRECT, Buttons.CLOSE])}
					secondaryButtonText={
						loading ? (
							<Loading size={LoadingSizes.MICRO} />
						) : (
							<div className="disp-flex train__center">
								<ISPIcon type={IconType.CART} size={IconSize.SMALL} className="m-r-2" variant={IconVariant.WHITE} />
								{t('common:add')}
							</div>
						)
					}
					secondaryButtonAction={handleSave}
					secondaryButtonId={getComponentId([Pages.MODAL, Components.ADD_TO_BASKET_DIRECT, Buttons.ADD])}
					error={errorMessage}
					errorDescription={
						<div className="train__flex-end m-2">
							<ISPIcon type={IconType.ERROR2} variant={IconVariant.BLOODRED} size={IconSize.SMALLER} />
							<span className="fs-16 m-l-2">{errorMessage}</span>
						</div>
					}
				>
					<Banner content={bannerContent} />
					<div className="p-h-4">
						<div className="add-via-modal-part-header p-h-4 color-brownish-grey fs-16 DaimlerCS-Regular lh-20 m-t-5">
							<div className="vehicle-part__item__part-number">{t('vehicles:part-number')}</div>
							<div className="train train__space-between vehicle-part__item__information">
								{t('common:information')}
							</div>
							<div className="disp-flex train__flex-end train__center no-white-space vehicle-part__item__price">
								<Trans i18nKey={getPriceTitle()} values={{ currency }} />
							</div>
							<div className="disp-flex train__center train__flex-end vehicle-part__item__quantity">
								{t('vehicles:quantity-title')}
							</div>
						</div>
						<div className="p-h-4">
							<Separator key={'header-list-seperator'} color={BorderVariant.ASPHALT} />
						</div>
						{part && (
							<PartItem
								tooltipIndex={0}
								vehiclePart={part}
								isHighlighted={false}
								isHighlightedItemPresent={false}
								hidePosition
								packageLoading={false}
								partPackageError={false}
								disableAddButton
								disableAvailabilityButton
								onStepperChange={handleStepperChange}
							/>
						)}
					</div>
				</Modal>
			)}
		</>
	);
};

export default AddToBasketDirectModal;
