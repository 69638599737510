import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useClickOutside from '@Helpers/hooks/useClickOutside';
import Icon, { IconType, IconSize, IconVariant } from '@isp/icon';
import { LinkVariant } from '@isp/link';
import ISPLinkWithIcon from '@isp/link-with-icon';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import './index.scss';

const ExportMenu = ({ children, alignLeft = false, id = '' }) => {
	const { t } = useTranslation(['common', 'orders']);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const menuWrapperRef = useRef<HTMLDivElement>(null);

	useClickOutside(menuWrapperRef, isClicked => setMenuOpen(!isClicked));

	const arrowIconType = menuOpen ? IconType.ARROW_UP : IconType.ARROW_DOWN;
	return (
		<div className="export">
			<ISPLinkWithIcon
				iconType={IconType.DOWNLOAD2}
				variant={LinkVariant.SECONDARY}
				className="DaimlerCS-Bold"
				onClick={e => {
					PushDataToTagManagerForButtons((e.target as HTMLElement).id, window.location.href);
					setMenuOpen(!menuOpen);
				}}
				id={id}
			>
				{t('common:export')}
				<Icon size={IconSize.SMALLER} type={arrowIconType} variant={IconVariant.BLACK} />
			</ISPLinkWithIcon>
			{menuOpen && (
				<div className="pos-rel z-index-5" ref={menuWrapperRef}>
					<div className="export__menu bg-white br-4 pos-abs" data-box data-align-left={alignLeft}>
						{children}
					</div>
				</div>
			)}
		</div>
	);
};

export const ExportMenuOption = ({ optionText, onClick, loading = false, id = '' }) => {
	const getMenuOptionClassName = () =>
		classNames('export__menu__option h-46-px w-100-p p-l-2 p-r-4 train train__center cursor-pointer', {
			'op-4 pointer-events-none': loading,
		});

	return (
		<div
			className={getMenuOptionClassName()}
			onClick={e => {
				PushDataToTagManagerForButtons((e.target as HTMLElement).id, window.location.href);
				onClick();
			}}
			id={id}
		>
			<div className="export__menu__option-text DaimlerCS-Bold">{optionText}</div>
		</div>
	);
};

export default ExportMenu;
