import { createRef, FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import ISPIcon, { IconSize, IconType, IconVariant } from '@isp/icon';
import ISPTooltip, { TooltipPositions, TooltipSizes, TooltipThemes } from '@isp/tooltip';
import PartProPlusLogo from '@Static/images/part_pro_plus_dealer_flag.svg';
import { Retailer } from '@Reducers/organization/models';
import { Events } from '@Constants/common';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { FinalVehicleType } from '@Constants/product';
import './index.scss';
import { PART_PRO_PLUS_DEALER_LOGO_SIZE } from '@Constants/retailer';

interface RetailerAssignedDivisionInfoProps {
	dealer: Retailer;
}
const ASSINGED_DIVISION_VEHICLE_TYPE_MAPPING = {
	[FinalVehicleType.PC]: 'CAR',
	[FinalVehicleType.VAN]: 'VAN',
	[FinalVehicleType.SMART]: 'SMART',
};
const RetailerAssignedDivisionInfo: FC<RetailerAssignedDivisionInfoProps> = ({ dealer }) => {
	const { t } = useTranslation(['shop']);

	const { name, assignedDivisionList, isPartsProPlus = false } = dealer || {};
	const hasPC = assignedDivisionList?.includes(ASSINGED_DIVISION_VEHICLE_TYPE_MAPPING[FinalVehicleType.PC]);
	const hasVAN = assignedDivisionList?.includes(ASSINGED_DIVISION_VEHICLE_TYPE_MAPPING[FinalVehicleType.VAN]);
	const hasSMART = assignedDivisionList?.includes(ASSINGED_DIVISION_VEHICLE_TYPE_MAPPING[FinalVehicleType.SMART]);

	const PCRef = createRef<HTMLDivElement>();
	const VANRef = createRef<HTMLDivElement>();
	const SMARTRef = createRef<HTMLDivElement>();
	return (
		<div>
			<div className="disp-flex train__center p-h-2">
				<RetailerAssignedDivisionInfoTooltip
					dataFor={`${name}-assigned-division-pc`}
					identifier={t('shop:division-name-pc')}
					iconTooltipRef={PCRef}
				>
					<ISPIcon
						type={IconType.PC_VEHICLE_TYPE}
						variant={hasPC ? IconVariant.DARKGRAY : IconVariant.GRAY}
						dataQa={DATA_QA.RETAILER_DROPDOWN_ASSIGNED_DIVISION_ICON}
					/>
				</RetailerAssignedDivisionInfoTooltip>

				<RetailerAssignedDivisionInfoTooltip
					dataFor={`${name}-assigned-division-van`}
					identifier={t('shop:division-name-van')}
					iconTooltipRef={VANRef}
				>
					<ISPIcon
						className="m-h-2"
						type={IconType.VAN_VEHICLE_TYPE}
						variant={hasVAN ? IconVariant.DARKGRAY : IconVariant.GRAY}
						dataQa={DATA_QA.RETAILER_DROPDOWN_ASSIGNED_DIVISION_ICON}
					/>
				</RetailerAssignedDivisionInfoTooltip>

				<RetailerAssignedDivisionInfoTooltip
					dataFor={`${name}-assigned-division-smart`}
					identifier={t('shop:division-name-smart')}
					iconTooltipRef={SMARTRef}
				>
					<ISPIcon
						type={IconType.SMART_VEHICLE_TYPE}
						variant={hasSMART ? IconVariant.DARKGRAY : IconVariant.GRAY}
						size={IconSize.SMALLER}
						dataQa={DATA_QA.RETAILER_DROPDOWN_ASSIGNED_DIVISION_ICON}
					/>
				</RetailerAssignedDivisionInfoTooltip>
			</div>
			{isPartsProPlus && (
				<Image
					src={PartProPlusLogo}
					alt="B2B Connect"
					width={PART_PRO_PLUS_DEALER_LOGO_SIZE.width}
					height={PART_PRO_PLUS_DEALER_LOGO_SIZE.height}
					className="p-h-2"
				/>
			)}
		</div>
	);
};

const RetailerAssignedDivisionInfoTooltip = ({ children, dataFor, identifier, iconTooltipRef }) => (
	<>
		<div
			data-tip
			ref={iconTooltipRef}
			data-effect="solid"
			data-event={Events.CLICK}
			data-for={dataFor}
			onMouseEnter={e => {
				ReactTooltip.show(iconTooltipRef.current);
				e.stopPropagation();
			}}
			onMouseLeave={e => {
				ReactTooltip.hide(iconTooltipRef.current);
				e.stopPropagation();
			}}
			className="train train__center"
		>
			{children}
		</div>
		<ISPTooltip
			className="retailer-dropdown__vehicle-info-tooltip"
			theme={TooltipThemes.DARK}
			place={TooltipPositions.BOTTOM}
			size={TooltipSizes.SMALL}
			id={dataFor}
			getContent={() => <div className="fs-12 m-1">{identifier}</div>}
		/>
	</>
);

export default RetailerAssignedDivisionInfo;
