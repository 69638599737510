import map from 'lodash/map';

export default function removeDealerSelector() {
	map(document.scripts, script => {
		if (script.src.includes('mb-dealer-select.js')) {
			document.body.removeChild(script);
		}
	});
	if (document.querySelector('[href*="mb-dealer-select.css"]')) {
		document
			.querySelector('[href*="mb-dealer-select.css"]')
			.parentNode.removeChild(document.querySelector('[href*="mb-dealer-select.css"]'));
	}
}
